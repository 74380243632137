.collegeSection1 {
    margin-bottom: 150px;
}

.scrollWheelStuff {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 145vw;
    gap: 15px;
}

.collegeMoblePopupText {
    margin-top: -50px;
}

.collegeStatusHolder {
    grid-template-columns: repeat(auto-fit, minmax(90%, 1fr));
    gap: 25px;
    background-size: contain;
    width: 255px !important;
    height: calc(255px * 0.2) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(20, 20, 20, 0.9);
    border: 4px solid rgba(255, 255, 255, 0.105);
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    text-decoration: none;
    min-height: 10%;
    transform: scale(1);
    transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
    animation: popIn 0.1s ease-out;
    margin: 3px;
}

.collegeStatusHolderImageSeperator {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-content: center;
}

.collegeRestrivitveText {
    text-align: center;
    max-width: 80vw;
}

.collegeStatusHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(20, 20, 20, 0.9);
    border: 4px solid rgba(255, 255, 255, 0.105);
    border-radius: 20px;
    padding: 20px !important;
    width: 305px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.cSHAccCollege {
    border: 4px solid rgba(153, 187, 0, 0.6) !important;
    background-color: rgba(137, 174, 0, 0.182) !important;
}

.cSHAccepted {
    border: 4px solid rgba(0, 124, 64, 0.6);
    background-color: rgba(0, 124, 64, 0.182);
}

.cSHRejected {
    border: 4px solid rgba(255, 0, 0, 0.304);
    background-color: rgba(124, 0, 0, 0.182);
}

.cSHWaitlisted {
    background-color: rgba(75, 75, 75, 0.9);
    border: 4px solid rgba(255, 255, 255, 0.105);
}

.cTextAccepted {
    color: rgb(0, 165, 66);
}

.cTextRejected {
    color: rgba(255, 0, 0, 0.671);
}

.collegeStatusHolder:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.collegeStatusHolderImageDiv {
    display: flex;
    flex-shrink: 0;
    padding-right: 10px;
    align-items: center;
}

.collegeStatusHolderImage {
    height: 65px;
    width: auto;
    padding: 0.25vw;
}

.collegeStatusHolderTextDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: white;
    font-size: 0.9em;
}

.collegeStatusName {
    font-weight: 900;
    font-size: 30px;
}

.collegeStatusStatus {
    font-style: 700;
    color: #ababab;
}

.ntfCollegeMargain {
    margin-bottom: 15px;
}

.centeredContainerCollegeDescriptionText {
    margin-bottom: 10px;
    font-size: 20px;
}

.particles-container {
    position: relative;
    width: 100%;
    height: auto;
}

.modalCollegeTopModalLogo {
    width: 100px;
    height: 100px;
    border-radius: 17.5px !important;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 10px;
    border-radius: 25%;
    object-fit: cover;
    margin-bottom: 10px;
    margin-right: 5px;
}

.modalCollegeTopLogo {
    height: 50px;
    margin-right: 20px;
}

.swiper {
    z-index: 0 !important;
}

.user-details-container {
    position: relative;
}

#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.swiper-wrapper {
    gap: 10px;
    width: inherit !important;
}

.swiper-slide swiper-slide-active {
    width: 10px;
}

.swiper-slide {
    width: inherit !important;
}

.akrAboutMeContainer {
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    justify-content: center;
    border-radius: 15px;
    align-items: center;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
}

.aboutMeCollageContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px;
}

.aboutMeCollageText {
    padding: 10px;
    color: black;
    max-width: 35vw;
    font-size: 20px;
    font-weight: 800;
}

.aboutMeCollageHarvard {
    padding: 10px;
}

.aboutMeCollageHarvard img {
    max-width: 70%;
    height: auto;
    border-radius: 12px;
}

.aboutMeCollageImage {
    padding: 10px;
}

.aboutMeCollageImage img {
    max-width: 425px;
    height: auto;
    border-radius: 130px 190px 130px 190px;
}

.collegesBannerText {
    margin-top: 1.25vw;
    font-size: 1.5vw;
    font-weight: 850;
    letter-spacing: 0.01vw;
    margin-bottom: 1.25vw;
}

.collegeMoblileModal {
    max-width: 30vw !important;
}

.flexDisplay {
    display: flex;
}

.flexAndCenterDisplay {
    display: flex;
    justify-content: center;
}

.flexAndCenterAndAllignDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageWithCaptionFramework {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageWithCaptionFramework .imageCaptionHarvard {
    text-align: left !important;
}

.collegeDetailsModalzSystem {
    background-color: rgba(20, 20, 20, 0.6);
    backdrop-filter: blur(5px);
    transition: backdropFilter 10s ease-in-out 0s;
    padding: 0.75vw 1.3vw 2vw 1.3vw !important;
    border: 5px solid rgba(255, 255, 255, 0.105) !important;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.collegeWidth80vw {
    padding: 0 10vw 0 10vw !important;
}

.iconInfoIcon {
    height: 43px;
    width: 43px;
}

.blurbMaxLinesSystemBraggery {
    max-width: 90%;
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    height: 135px !important;
    overflow: auto;
    padding: 0 7.5px;
}

.collegeDemoArticle {
    margin-left: 12.5%;
    margin-top: 5%;
}

.blurbMaxLinesSystemBraggery::-webkit-scrollbar {
    width: 2.5px !important;
}

.collegeDisplayDemoArticleTimestamp {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 0.6vw;
}

.demoArticleText pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.demoArticleText {
    margin-top: 2vw;
    font-size: 20px;
    font-weight: 700;
    opacity: 0.9;
    max-width: 90%;
    margin-bottom: 6.5vw;
}

.noMargain {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.blackBackground {
    background-color: rgba(0, 0, 0, 0) !important;
}

.smallMargainSubverb {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

@media (max-width: 900px) {
    .collegeDemoArticle {
        margin-left: inherit;
        margin-top: 10%;
    }

    .content-container {
        flex-direction: column;
    }

    .text,
    .image {
        padding: 5px;
    }

    .collegeDisplayDemoArticleTimestamp {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 2.5vw;
    }

    .demoArticleText {
        text-align: center;
        font-size: 4vw;
        margin-left: auto;
        margin-right: auto;
    }


    .aboutMeCollageText {
        max-width: 100vw;
        text-align: center;
    }

    .mobileCenterBioText {
        margin-left: -10px !important;
    }

    .collegeMiniSocialCard {
        width: inherit !important;
        max-width: 80vw !important;
        justify-content: center !important;
    }

    .aboutMeCollageContentContainer {
        gap: inherit;
        justify-content: center;
    }

    .collegesBannerText {
        margin-top: 4vw;
        font-size: 5vw;
        margin-bottom: 4vw;
    }

    .collegeMoblileModal {
        max-width: inherit !important;
        width: 82vw !important;
    }

    .collegeDetailsModalzSystem {
        padding: 10px 1.25vw 1.5vw 1.25vw !important;
        text-align: center;
    }
}